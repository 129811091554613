/**
 * Client-side application settings for the local development environment.
 */
export const defaultConfig = {
  // Core application settings
  app: {
    name: 'My Account',
    host: process.env.CONFIG_APP_HOST || 'local.lawpilots.eu:3000',
    env: 'local' as 'local' | 'test' | 'production' | 'development'
  },
  // API and Auth endpoint(s)
  api: {
    origin: process.env.CONFIG_API_ORIGIN || 'http://api.local.lawpilots.eu:8012',
    path: '/api',
    prefix: '/tenant',
    subdomain: '/subdomain',
    redirect_url: process.env.CONFIG_API_REDIRECT_URL || 'http://local.lawpilots.eu:3000'
  },
  launchDarkly: {
    clientSideId: process.env.LAUNCHDARKLY_STAGE_CLIENT_ID || 'mock-client-id',
    baseUrl: 'http://wiremock:8090/launchdarkly',
    streaming: true
  },
  scimserver: {
    origin: process.env.CONFIG_SCIMSERVER_ORIGIN || 'http://api.local.lawpilots.eu:8012/scim'
  }
}

/**
 * Client-side application settings for the test environment.
 */
export const test: typeof defaultConfig = {
  app: {
    ...defaultConfig.app,
    host: 'lawpilots.eu',
    env: 'test'
  },
  api: {
    ...defaultConfig.api,
    origin: 'https://api.lawpilots.eu',
    redirect_url: 'https://lawpilots.eu'
  },
  launchDarkly: {
    ...defaultConfig.launchDarkly,
    clientSideId: process.env.LAUNCHDARKLY_STAGE_CLIENT_ID || defaultConfig.launchDarkly.clientSideId,
    streaming: true
  },
  scimserver: {
    ...defaultConfig.scimserver
  }
}

/**
 * Client-side application settings for the production environment.
 */
export const production: typeof defaultConfig = {
  app: {
    ...defaultConfig.app,
    host: 'lawpilots.net',
    env: 'production'
  },
  api: {
    ...defaultConfig.api,
    origin: 'https://api.lawpilots.net',
    redirect_url: 'https://lawpilots.net'
  },
  launchDarkly: {
    ...defaultConfig.launchDarkly,
    clientSideId: process.env.LAUNCHDARKLY_PROD_CLIENT_ID || defaultConfig.launchDarkly.clientSideId,
    streaming: true
  },
  scimserver: {
    ...defaultConfig.scimserver,
    origin: 'https://scimserver.lawpilots.net/scim'
  }
}

/**
 * Client-side application settings for the development environment.
 */

export const development: typeof defaultConfig = {
  app: {
    ...defaultConfig.app,
    host: 'lawpilots.dev',
    env: 'development'
  },
  api: {
    ...defaultConfig.api,
    origin: 'https://api.lawpilots.dev',
    redirect_url: 'https://lawpilots.dev'
  },
  launchDarkly: {
    ...defaultConfig.launchDarkly,
    clientSideId: process.env.LAUNCHDARKLY_STAGE_CLIENT_ID || defaultConfig.launchDarkly.clientSideId,
    streaming: true
  },
  scimserver: {
    origin: 'https://api.lawpilots.dev/scim'
  }
}

export type Config = typeof defaultConfig
export default { defaultConfig, test, development, production }
