import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { East as EastIcon } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { AppBar, Box, Button, Dialog, Grid, IconButton, Slide, Stack, Toolbar, Typography } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'

import { CompanyLogo } from '@/common/components'
import ImageToSvgConverter from '@/common/components/ImageToSvgConverter/ImageToSvgConverter'
import { ParticipantsImg } from '@/common/components/ParticipantsImg'
import { languages, TrainingParticipationData } from '@/features/my/types'
import { useTrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostMutation } from '@/services/api/tenant/trainings'
import { RootState, useTypedSelector } from '@/store'
import i18next from '../../../../../locales/i18nInstance'
import { CustomRadioGroup } from '../CustomRadioGroup/CustomRadioGroup'
import LanguageButton from '../LanguageButton/LanguageButton'
import { useMediaQueryMatch } from '@/hooks'
import {
  AppBarHeaderStyle,
  BackButtonStyle,
  BoxStyle,
  ContinueButtonStyle,
  CourseSelectTypographyStyle,
  DescriptionTypographyStyle,
  GridContainerStyle,
  GridItemStyle,
  IconButtonStyle,
  ImprintButtonGridItemStyle,
  ImprintButtonStyle,
  PageLanguageGridItemStyle,
  PageLanguageStackStyle,
  PageLanguageTypographyStyle,
  TitleTypographyStyle,
  WelcomeTypographyGridItemStyle,
  WelcomeTypographyStyle
} from './TrainingDialogsStyles'

export const i18n = i18next

const Transition = React.forwardRef(
  (props: TransitionProps & { children: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
)

type TrainingDialogsProps = { open: boolean; handleClose: () => void; training: TrainingParticipationData | undefined }

export const TrainingDialogs = ({ open, training, handleClose }: TrainingDialogsProps): JSX.Element => {
  const [selectedLanguage, setSelectedLanguage] = useState<'en-GB' | 'de-DE'>('en-GB')
  const { t } = useTranslation('Lmsplus_Course_Intro_Page')
  const accessedTenant = useTypedSelector((state: RootState) => state.authSlice.tenant)

  const [startTrainingMutation] =
    useTrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostMutation()

  const handleLanguageClick = (languageCode: 'en-GB' | 'de-DE') => {
    i18n.changeLanguage(languageCode, () => {
      setSelectedLanguage(languageCode)
    })
  }

  const [selectedLanguageId, setSelectedLanguageId] = React.useState<string>('')

  const handleLanguageSelect = (id: string) => {
    setSelectedLanguageId(id)
  }

  const isInvited = training?.status === 'invited'

  const isLgScreen = useMediaQueryMatch('lg')
  const isGreaterThanLgScreen = useMediaQueryMatch({ customMediaQuery: '(min-width: 1025px)' })

  const startTraining = useCallback(async () => {
    if (isInvited) {
      try {
        const result = await startTrainingMutation({
          tenantId: accessedTenant.id,
          trainingCode: training?.trainingCode || '',
          trainingCycleId: training?.trainingCycleId || '',
          trainingStartInDto: {
            course_variant_id: selectedLanguageId
          }
        }).unwrap()
        window.location.replace(result.courseplay_url)
      } catch (error) {
        console.error(error)
      }
    } else if (training?.coursePlayUrl) {
      window.location.replace(training.coursePlayUrl)
    } else {
      console.error('No course URL available')
    }
  }, [isInvited, training, accessedTenant.id, selectedLanguageId, startTrainingMutation])

  return (
    <>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={AppBarHeaderStyle}>
          <Toolbar>
            <IconButton edge="start" color="inherit" sx={IconButtonStyle}>
              {accessedTenant.logo_url ? (
                <ImageToSvgConverter imageString={accessedTenant.logo_url} />
              ) : (
                <CompanyLogo />
              )}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={BoxStyle}>
          <Grid container justifyContent="center" alignItems="center" spacing={2} sx={GridContainerStyle}>
            <Grid item xs={12} sx={{ display: 'block' }}>
              <ParticipantsImg />
            </Grid>
            <Grid item xs={12} sx={{ ...GridItemStyle, ...WelcomeTypographyGridItemStyle }}>
              <Typography sx={WelcomeTypographyStyle}>
                {isInvited ? t('trainings_welcome') : t('continue_course')}
              </Typography>
            </Grid>
            {isInvited && (
              <>
                <Grid item xs={12} sx={GridItemStyle}>
                  <Typography variant="h4" sx={DescriptionTypographyStyle}>
                    {t('trainings_description')}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12} sx={GridItemStyle}>
              <Typography variant="h4" sx={TitleTypographyStyle}>
                {training ? training.title : 'Default Title'}
              </Typography>
            </Grid>
            {isInvited && (
              <>
                <Grid item xs={12} sx={GridItemStyle}>
                  <Typography variant="h4" sx={CourseSelectTypographyStyle}>
                    {t('trainings_courseSelect')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={GridItemStyle}>
                  <CustomRadioGroup
                    defaultValue="optionA"
                    courseId={training?.courseId || ''}
                    onLanguageSelect={handleLanguageSelect}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sx={GridItemStyle}>
              <Button
                variant="outlined"
                endIcon={<EastIcon fontSize="small" />}
                sx={ContinueButtonStyle(accessedTenant?.theme_color)}
                onClick={startTraining}>
                {t('trainings_continueButton')}
              </Button>
            </Grid>
            {isLgScreen && (
              <Grid item xs={12} sx={GridItemStyle}>
                <Button
                  autoFocus
                  onClick={handleClose}
                  sx={BackButtonStyle}
                  startIcon={<ArrowBackIcon fontSize="small" />}>
                  {t('back_account')}
                </Button>
              </Grid>
            )}
            <Grid item xs={12} sx={{ ...GridItemStyle, ...PageLanguageGridItemStyle }}>
              <Stack direction="row" spacing={0.5} sx={PageLanguageStackStyle}>
                <Typography sx={PageLanguageTypographyStyle}>{t('show_this_page')}</Typography>
                {languages.map(({ language, code }) => (
                  <LanguageButton
                    key={code}
                    language={language}
                    languageCode={code}
                    selectedLanguage={selectedLanguage}
                    onClick={handleLanguageClick}
                  />
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{ ...GridItemStyle, ...ImprintButtonGridItemStyle }}>
              {isLgScreen && (
                <Stack spacing={2} marginRight={'88px'}>
                  <Button href="https://lawpilots.com/de/datenschutzerklaerung/" sx={ImprintButtonStyle}>
                    {t('data_privacy')}
                  </Button>
                  <Button href="https://lawpilots.com/de/impressum/" sx={ImprintButtonStyle}>
                    {t('imprint')}
                  </Button>
                </Stack>
              )}
              {isGreaterThanLgScreen && (
                <>
                  <Button href="https://lawpilots.com/de/datenschutzerklaerung/" sx={ImprintButtonStyle}>
                    {t('data_privacy')}
                  </Button>
                  <Button href="https://lawpilots.com/de/impressum/" sx={ImprintButtonStyle}>
                    {t('imprint')}
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        {isGreaterThanLgScreen && (
          <Button autoFocus onClick={handleClose} sx={BackButtonStyle} startIcon={<ArrowBackIcon fontSize="small" />}>
            {t('back_account')}
          </Button>
        )}
      </Dialog>
    </>
  )
}
